<template>
    <div class="app-container">
      <!-- banner -->
      <div class="banner">
        <img  :src="bannerData.pictureUrl" class="pos-rl" v-if="bannerData.picture" :onerror="getImage(bannerData)" alt="" />
      </div>
      <!-- news-list -->
      <div class="job columns inner">
        <div class="job-box">
          <!-- 招聘详情 -->
          <div class="job-detail-box" >
            <div class="title">
                <h4 class="dis-inbl" v-html="pageData.name"></h4>
                <div class="title-info">
                  <span><svg-icon icon-class="location" :size="14"/>{{pageData.addressName}}</span>
                </div>
              </div>
              <div class="detail p16-txt" v-html="pageData.jobDescription">
            </div>
             <div class="left-button dis-flex">
                <el-button class="deliver-btn blue-btn" @click="goToDlivery(pageData,1)">{{$t('common.delivery')}}</el-button>
                <el-button class="deliver-btn " @click="goToDlivery(pageData,2)">{{$t('common.referdelivery')}}</el-button>
              </div>
          </div>
          <div v-if="detailDialog">
            <el-dialog title="提示" :visible.sync="detailDialog" width="40%" center :before-close="handleClose" custom-class="delivery-dialog">
              <Input v-if="detailShow==1" :rowData="rowData" @cancel="handleClose"></Input>
              <InputRefer v-else :rowData="rowData" @cancel="handleClose" ></InputRefer>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
    </template>
    
    <script>
    import Cookies from 'js-cookie'
    import router from '@/router'
    import {post, del, get, putUrl} from "@/api/http";
    import request from "@/utils/request.js";
    import Input from "./Recruit-input.vue";
    import InputRefer from "./Recruit-input-refer.vue";
    
    export default {
      name: 'Home',
      components: { Input,InputRefer},
      data() {
        return {
          pageConfig: {
            pageSize: 9,
            total: 0,
            currentPage: 1,
            pageSizes: [
                10, 50, 100, 150, 200]
          },
          searchData:{},
          rowData:{},
          parentData:{},
          pageData:{},
          pageList:[],
          bannerData:{},
          detailShow:0,
          detailDialog:false
        }
      },
      methods: {
        handleCurrentChange(val) {
            this.pageConfig.currentPage = val;
            this.getList()
        },
        handleSearch() {
            this.pageConfig.currentPage = 1;
            this.getList()
        },
        getList(){
          post("/joinUs/getPage",{
            page:this.pageConfig.currentPage,
            limit:this.pageConfig.pageSize,
            status:2,
            name:this.searchData.name
          }).then(res => {
              if (res.code == 1) {
                  this.pageList=res.data.data
                  this.pageConfig.total=res.data.count
              } else {
                  this.$message.error(res.data);
              }
          });
        },
        getData(){
          if(this.parentData.id){
            post("/joinUs/getDetails",{id:this.parentData.id}).then(res => {
                if (res.code == 1) {
                    this.pageData=res.data
                    // this.pageData=res.data.business[0]
                    // this.bannerData=res.data.homeIntroduction[0]
                } else {
                    this.$message.error(res.data);
                }
            });
          }
        },
        getBgStyle(item){
          if (item.picture) {
            return {
            backgroundImage:
              'url(' + item.picture + ') ',
            }
          } else {
            return {
            backgroundImage:
              'url(' +
              require('../../../static/news-banner.jpg') +
              ') ',
            }
          }
        },
        getImage(row) {
          setTimeout(function () {
            if (!row.pictureUrl){
              request({
                url: "/file/download",
                method: "POST",
                data: {
                    id:row.picture
                },
                isDownLoadFile: true,
                isParams: true,
                responseType: "blob"
              }).then(res=>{
                const blob = new Blob([res], {
                    type:"application/pdf"
                });
                const linkNode = document.createElement("a");
                linkNode.style.display = "none";
                linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                document.body.appendChild(linkNode);
                row.pictureUrl =  linkNode.href 
              })
            }
          }.bind(this), 1000);
        },
        goToDlivery(data,type){
          this.detailShow=type
          this.rowData = data
          this.detailDialog = true
        },
        handleClose(data,type){
            this.detailDialog = false
        },
      },
      mounted() {
        this.$router.afterEach((to,form,next)=>{
            window.scrollTo(0,0)
        })
        this.parentData = this.$route.query
        console.log(this.parentData);
        this.getList()
        this.getData()
    
        // this.navIndex = this.$route.path
        // if (Cookies.get('ClinLan') == 'en') {
        //   this.language = 'English'
        // } else {
        //   this.language = '中文'
        // }
      },
    }
    </script>
    <style lang="scss" scoped>
    .inner{
      width: 1620px;
      margin:0 auto;
    }
    .columns{
      padding:100px 0
    }
    .center-header{
      text-align: center;
        h3{
          font-size: 48px;
          line-height: 90px;
        }
        span{
          font-size: 22px;
          font-weight: 300;
        }
    }
    .left-button{
      justify-content: start;
      margin-top: 50px;
      .deliver-btn{
        color:#fc7700;
        background-color: #FFF5EC;
        border-color: #FFF5EC;
        border-radius: 25px;
        padding:15px 30px;
        // margin:20px 0;
        height: 45px;
      }
      .blue-btn{
        background-color: #f0f9ff;
        border-color: #f0f9ff;
        color: #2ca9f9;
      }
    }
    // banner
    .banner{
      background-color: #F2F7FD;
      background-image: url('../../../static/news-banner.jpg');
      background-size:cover;
      width: 100%;
      position: relative;
      height: 410px;
      overflow: hidden;
    .left{
      position: absolute;
      left: 250px;
      width:32%;
      top:110px;
      color:#fff;
    
      h2{
        font-weight: 800;
        font-size: 76px;
      }
      .intro-text{
        font-size: 22px;
        font-weight: 300;
        line-height: 40px;
        margin-top:20px;
      }
    }
    }
    
    .job{
      padding:100px 0 100px;
      // margin: 0 100px;
      // .job-box{
      //   margin-top: 100px;
      // }
      .job-infomation{
        margin-top: 50px;
        padding:30px;
        font-weight: 300;
        background-color: #F4F4F5;
        border-radius: 20px;
        line-height: 1.5;
        .job-btn{
          color:#fff;
          background-color: #0D63DC;
          border-color: #0D63DC;
          border-radius: 25px;
          padding:17px 45px;
          // margin:20px 0;
        }
      }
      .job-detail-box{
        padding:50px;
        width:100%;
        box-shadow: 0px 20px 27px 0px rgb( 246, 246, 246 );
        border-radius: 20px;
        box-sizing: border-box;
        .title{
            h4{
            line-height: 1.5;
            margin-bottom: 25px;
            height: 35px;
            overflow : hidden;
            display: -webkit-box;    /*弹性伸缩盒子*/
            -webkit-box-orient: vertical; /*子元素垂直排列*/
            -webkit-line-clamp: 1;   /*可以显示的行数，超出部分用...表示*/
            text-overflow: ellipsis; 
            }
            .title-info{
            margin-bottom: 30px;
            .svg-icon{
                color:#666666;
                padding-right: 5px;
            }
            }
            span{
            font-size: 16px;
            color:#5c5c5c;
            margin-right: 20px;
            }
        }
        .detail{
            font-size: 16px;
            line-height: 1.5;
            width: 100%;
            color:#333;
            // overflow-x: hidden;
            // display: -webkit-box;    /*弹性伸缩盒子*/
            // -webkit-box-orient: vertical; /*子元素垂直排列*/
            // -webkit-line-clamp: 3;   /*可以显示的行数，超出部分用...表示*/
            // text-overflow: ellipsis; 
        }
      }
    }
    .pagination{
      margin:80px auto 100px;
      justify-content: center;
    }
    ::v-deep .el-pagination.is-background {
      .btn-next, .btn-prev, .el-pager li{
        background-color: #3960D9;
        color:#fff;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        line-height: 50px;
      }
      .el-pager li:not(.disabled).active{
        background-color: #262626;
      }
    }
    ::v-deep .el-form{
      .el-radio-button .el-radio-button__inner{
        border:none
      }
      .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        background-color: #0D63DC;
        border-radius: 20px;
      }
    }
    ::v-deep .el-input{
      font-size: 16px;
      .el-input__inner{
        border:#0D63DC 2px solid;
        height: 60px;
        border-radius: 20px 0 0 20px;
      }
      .el-input-group__append{
        border:#0D63DC 2px solid;
        background-color:#0D63DC ;
        color:#fff;
        padding:0 40px;
        border-radius: 0 20px 20px 0;
      }
    }
    
    </style>
<style lang="scss">
.delivery-dialog{
    width: 435px;
    background-size: 435px 316px;
    border:none;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: none;
    overflow: hidden;
    .el-dialog__header{
        background-image: url('../../assets/dialog.png');
        background-size: 780px 50px;
        background-repeat: no-repeat;
        padding:15px 0;
        .el-dialog__title{
            font-weight: bold;
            font-size: 16px;
            color:#fff
        }
        .el-dialog__headerbtn .el-dialog__close::before{
            font-weight: bold;
            font-size: 16px;
            color: #fff;
        }
    }
}
</style>